import { isE2E, isPrStage, isSandbox } from "./utils";

const isMatchers: Record<string, (stage: string) => boolean> = {
  sandbox: isSandbox,
  pr: isPrStage,
  e2e: isE2E,
};

function regexMatcher(key: string, stage: string): boolean {
  return new RegExp(key).test(stage);
}

function isMatcher(key: string, stage: string): boolean {
  const matcherNames = key.substring(3).split("|");

  for (const matcherName of matcherNames) {
    if (!isMatchers[matcherName]) {
      console.error(`Unknown stage: ${matcherName}`);

      continue;
    }

    if (isMatchers[matcherName](stage)) {
      return true;
    }
  }

  return false;
}

function exactMatcher(key: string, stage: string): boolean {
  return stage === key;
}

function getMatcher(key: string): (key: string, stage: string) => boolean {
  //To be safe, only treat a stage as regex if it begins with ^ and ends with $
  if (key.startsWith("^") && key.endsWith("$")) {
    return regexMatcher;
  }

  if (key.startsWith("is:")) {
    return isMatcher;
  }

  return exactMatcher;
}

export function getStageSpecificValue(stage: string, values: { [key: string]: string }) {
  const entries = Object.entries(values).map(([key, value]) => {
    value = value.replace("#STAGE#", stage);

    return [key, value];
  });

  for (const [key, value] of entries) {
    const matcher = getMatcher(key);

    if (matcher(key, stage)) {
      return value;
    }
  }
  return values.default;
}
